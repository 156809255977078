  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
  body {
    // font-family: "Inter", sans-serif;
    // font-family: "Poppins", sans-serif;
    // font-family: "Roboto", sans-serif;
    // font-family: "Open Sans", sans-serif;
  }
  
  .l {
    box-shadow: inset 0 0 0 1px red;
  }
  
  .icon {
    stroke: none;
    color: currentColor;
    fill: currentColor;
    width: 1em;
    height: 1em;
  }
  
  .g-10px,
  .gx-10px {
    --bs-gutter-x: 10px;
  }
  
  .g-10px,
  .gy-10px {
    --bs-gutter-y: 10px;
  }
  
  .Robotoff {
    font-family: 'Roboto', sans-serif;
  }
  
  .Poppinsff {
    font-family: 'Poppins', sans-serif;
  }
  
  .Interff {
    font-family: 'Inter', sans-serif;
  }
  /* Firefox */
  
  * {
    scrollbar-width: auto;
    // scrollbar-color: #C8A366;
  }
  /* Chrome, Edge, and Safari */
  
  *::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    // background: #c6c6c6;
    background: transparent;
    border-radius: 10px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #1a87d9;
    // background-color: #097ba9;
    border-radius: 10px;
    // border: 2px solid #ffffff;
    border: 1px solid #ffffff;
  }
  
  .lh-md {
    line-height: 1.5 !important;
  }
  
  .maxw-900 {
    max-width: 900px;
  }
  
  .maxw-1000 {
    max-width: 1000px;
  }
  
  .maxw-1100 {
    max-width: 1100px;
  }
  
  .maxw-600 {
    max-width: 600px;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }
  
  .fs-14px {
    font-size: 14px !important;
  }
  
  .fs-16px {
    font-size: 16px !important;
  }
  
  .fs-5p2 {
    font-size: 1.375rem !important;
  }
  
  .fs-4p2 {
    font-size: 2.375rem !important;
  }
  
  .fw-400 {
    font-weight: 400;
  }
  
  .fw-500 {
    font-weight: 500;
  }
  
  .fw-600 {
    font-weight: 600;
  }
  
  .fw-700 {
    font-weight: 700 !important;
  }
  
  .fw-800 {
    font-weight: 800;
  }
  
  .pt-32px {
    padding-top: 32px;
  }
  
  nav {
    .navbar-brand {
      width: 163px;
      height: 58px;
      max-height: 58px;
      img {
        width: 100%;
      }
    }
    padding: 0.5rem 0;
    .search-icon {
      top: 0;
      margin-left: 15px;
      margin-top: 10px;
      font-size: 22px;
    }
  }
  
  .fs-24 {
    font-size: 24px;
  }
  
  header {
    // box-shadow: 0px 0px 4px 0px #00000040;
    //
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 0px 4px 0px #00000040;
    .btn-login {
      width: 92px;
      height: 44px;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .country-box {
      .country-btn {
        border: none;
        outline: none;
        background: none;
        padding: 0;
        margin: 0;
        &:hover,
         :active {
          opacity: 0.7;
        }
        .icon {
          transition: 300ms;
        }
        &[aria-expanded=true] {
          .icon {
            transform: rotate(180deg);
          }
        }
        span {
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-weight: 500;
        }
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .icon {
          font-size: 12px;
        }
      }
    }
  }
  
  .btn-coin {
    min-width: 124px;
    // min-width: 124px;
    // width: 124px;
    height: 44px;
    border: 1px solid #eaecf0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    &:hover,
     :active {
      opacity: 0.7;
    }
    img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 50%;
    }
    span {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
  
  .use-btn {
    min-width: 84px;
    // width: 84px;
    max-width: 118px;
    height: 44px;
    span {
      padding-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .dropdownList {
    position: fixed;
    left: 0;
    // margin-top: 114px;
    margin-top: 115.5px;
    background-color: #fff;
    width: 100%;
    z-index: 1002;
    .country-list {
      padding-bottom: 16px;
      .offcanvas-right {
        list-style-type: none;
        padding: 0 0px;
        margin: 0;
        .nav-link {
          text-decoration: none;
          height: 44px;
          padding-left: 15px;
          padding-right: 10px;
          // margin-top: 10px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          font-weight: 500;
          transition: 300ms;
          border-radius: 8px;
          position: relative;
          cursor: pointer;
          &.active,
          &:hover {
            background: #1a87d91a;
          }
          strong {
            padding-left: 8px;
            pointer-events: none;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
    .offcanvas-header {
      display: none !important;
    }
    h5 {
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-weight: 600;
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
  
  #columnslist {
    -moz-column-count: 2;
    // -moz-column-gap: 20px;
    -webkit-column-count: 2;
    // -webkit-column-gap: 20px;
    column-count: 2;
    // column-gap: 20px;
  }
  
  .navbar {
    .navbar-toggler-icon {
      font-weight: 800;
    }
    .navbar-nav {
      padding: 0;
      font-family: 'Inter', sans-serif;
      .nav-link {
        color: #000;
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid #1a87d9;
          outline: none;
          color: #00000080;
        }
        &.active {
          border-bottom: 2px solid #1a87d9;
        }
      }
    }
  }
  
  .wrapper {
    padding-top: 116px !important;
  }
  
  .product {
    background-color: #eff1f5;
  }
  
  .game-imgbox {
    width: 100%;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-fit: fill;
      border-radius: 8px;
    }
  }
  
  .game-content {
    .game-smimgbox {
      width: 52px;
      height: 52px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: fill;
        border-radius: 8px;
      }
    }
    .game-heading {
      font-size: 18px;
      font-weight: 600;
      font-family: 'Roboto', sans-serif;
    }
    .icon {
      width: 16px;
      height: 16px;
    }
    span {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
  }
  
   ::placeholder {
    color: #00000080;
    opacity: 50% !important;
    /* Firefox */
  }
  
   ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #00000080;
  }
  
  .content-wrapper {
    .details-heading {
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
    .question-btn {
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      background: none;
      margin-left: 10px;
      margin-top: -3px;
      .icon {
        width: 24px;
        height: 24px;
      }
    }
    .input-label {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 16px;
    }
    .input-box {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 16px;
      // border: 1.5px solid #C3D6E4;
      border: 2px solid #C3D6E4;
      height: 56px;
      background: #F9FAFB;
    }
    .form-control:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: #1a87d9;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    .check-name {
      margin-top: 10px;
      color: #39cd81;
      .icon {
        width: 16px !important;
        height: 16px !important;
        font-size: 16px !important;
      }
      span {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  
  .btn-login {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1a87d9;
    --bs-btn-border-color: #1a87d9;
    --bs-btn-hover-color: #1a87d9;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #1a87d9;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1a87d9;
    --bs-btn-disabled-border-color: #1a87d9;
    border: 2px solid #1a87d9;
  }
  
  .search-field {
    border: 2px solid #1a87d9;
    padding-left: 50px;
    padding-right: 20px;
    width: 320px;
    max-width: 320px;
    height: 44px;
    background: #F9FAFB;
    display: flex;
    align-items: center;
    color: #00000080;
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    &:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0px 0px 8px 4px rgba(26, 135, 217, 0.25);
    }
  }
  
  .card-label {
    display: block;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      &:checked~.cl-content {
        background: #1a87d91a;
        box-shadow: inset 0 0 0 2px #1a87d9;
        &::before {
          transform: scale(1);
        }
        &::before,
        &::after {
          visibility: visible;
        }
      }
    }
    .cl-content {
      position: relative;
      overflow: hidden;
      // min-height: 132px;
      background: #fff;
      padding: 22px 15px;
      padding: 22px 15px;
      border-radius: 12px;
      display: flex;
      // box-shadow: 0px 0px 20px 0px #0e2f5633;
      border: 2px solid #EAECF0;
      &::before {
        content: '';
        position: absolute;
        // width: 50px;
        // height: 50px;
        width: 45px;
        height: 45px;
        // background-color: red;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z' clip-rule='evenodd'/></svg>");
        background-repeat: no-repeat;
        // background-size: 1.5rem 1.5rem;
        background-size: 1.4rem 1.4rem;
        // background-position: 20px 8px;
        background-position: 20px 4px;
        top: 0;
        right: 0;
        z-index: 1;
        transform: scale(0.5);
        visibility: hidden;
        transition: 400ms;
      }
      &::after {
        content: '';
        position: absolute;
        // width: 80px;
        // height: 80px;
        width: 64px;
        height: 64px;
        background-color: #1a87d9;
        // top: -40px;
        // right: -40px;
        top: -32px;
        right: -32px;
        z-index: 0;
        transform: rotate(-45deg);
        visibility: hidden;
      }
      .cl-badge {
        position: absolute;
        top: 0;
        left: -2px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 1;
        background: red;
        color: #fff;
        padding: 0 12px;
        border-radius: 0 0 5px 0;
        transform: skewX(-20deg);
        small {
          display: block;
          transform: skewX(20deg);
        }
      }
      .cr-badge {
        position: absolute;
        bottom: 0;
        right: -2px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 1;
        background: red;
        color: #fff;
        padding: 0 12px;
        border-radius: 5px 0 0 2px;
        transform: skewX(-20deg);
        small {
          display: block;
          transform: skewX(20deg);
        }
      }
      .cl-title {
        width: 100%;
        padding-right: 8px;
        font-family: 'Roboto', sans-serif;
        h4 {
          font-size: 16px;
          font-weight: 400;
        }
        span {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .cl-icon {
        width: 64px;
        min-width: 54px;
        align-self: center;
        img {
          width: 100%;
          display: block;
        }
      }
      .cl-price {
        position: absolute;
        font-size: 18px;
        font-weight: 500;
        bottom: 20px;
        left: 16px;
        margin: 0;
        font-family: 'Roboto', sans-serif;
      }
    }
    .payment-card {
      min-height: 116px !important;
      .left-coin {
        display: flex;
        align-items: center;
        .img-box {
          width: 64px;
          height: 64px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
        .coin-name {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 500;
          margin-left: 10px;
        }
      }
      .right-coin {
        .img-box {
          width: 20px;
          height: 20px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
        .coin-value {
          font-family: 'Roboto', sans-serif;
          font-size: 18px;
          font-weight: 500;
        }
        .rupee {
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: 400;
        }
        .old-price {
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: 400;
          color: #00000080;
        }
        .img-upibox {
          width: 32px;
          height: 32px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .img-upimore {
          width: 8px;
          height: 8px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 25px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .left-upi {
        display: flex;
        align-items: center;
        .img-box {
          width: 64px;
          height: 30px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
        .coin-name {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }
  
  .min-h132px {
    min-height: 132px;
  }
  
  footer {
    background: #111215;
    h6 {
      font-family: 'Inter', sans-serif;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: rotate(45deg);
        display: none;
        transition: 300ms;
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        padding-bottom: 8px;
        a {
          text-decoration: none;
          color: #a9a9a9;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-weight: 500;
          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
    .download-app {
      width: 216px;
      height: 112px;
      background: #50505080;
      border-radius: 8px;
      .white-box {
        width: 88px;
        height: 88px;
        border-radius: 8px;
        background-color: #ffffff;
        text-decoration: none;
      }
      .game-box {
        width: 62px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-decoration: none;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .copyright {
      border-top: 1px solid #a9a9a9;
      color: #a9a9a9;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    .footer-socialicon {
      h5 {
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
      }
      a {
        width: 24px;
        height: 24px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .youtube {
        color: #ff0000;
        width: 28px;
        height: 20px;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .collapse:not(.show) {
      display: block;
    }
  }
  
  // 
  .detail-modal {
    p {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: #000;
    }
    .img-details {
      width: 100%;
      height: 190px;
      border-radius: 0px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  
  .back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    // width: 26px;
    // height: 24px;
    width: 32px;
    height: 30px;
    .icon {
      // width: 16px;
      // height: 18px;
      width: 20px;
      height: 22px;
    }
  }
  
  .navbar-toggler:focus {
    box-shadow: none;
  }
  
  .btn-close:focus {
    /* box-shadow: var(--bs-btn-close-focus-shadow); */
    box-shadow: none;
  }
  
  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5;
  }
  
  .btn-pay {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1a87d9;
    --bs-btn-border-color: #1a87d9;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #156BAF;
    --bs-btn-hover-border-color: #156BAF;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #156BAF;
    --bs-btn-active-border-color: #156BAF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1a87d9;
    --bs-btn-disabled-border-color: #1a87d9;
    height: 64px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  
  .offcanvas-logo {
    width: 80px;
    height: 28px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  
  .offcanvas-header {
    box-shadow: 0px 0px 4px 0px #00000040;
  }
  
  .offcanvas-body {
    padding: 0px 8px;
    .offcanvas-aside {
      list-style-type: none;
      padding: 0;
      margin: 0;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      .nav-link {
        text-decoration: none;
        height: 44px;
        padding-left: 15px;
        padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        transition: 300ms;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        &:hover {
          background: #1a87d91a;
        }
        span {
          .icon {
            width: 24px;
            height: 24px;
          }
        }
        strong {
          padding-left: 12px;
          pointer-events: none;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    button {
      position: absolute;
      left: 20px;
      bottom: 20px;
      box-shadow: 0px 0px 4px 0px #00000040;
      outline: none;
      width: 105px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      border: none;
      background: #fff;
      .icon {
        width: 20px;
        height: 20px;
      }
      &:hover,
       :active {
        background-color: #1a87d91a;
      }
    }
  }
  
  .lanlist-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.9);
    background: rgba(15, 15, 15, 0.594);
    z-index: 1001;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: 300ms;
  }
  
  // slider start
  /* Default slide */
  
  .center-slider {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .slick-slide:not(.slick-center) {
    transform: scale(0.95, 0.75);
    opacity: 0.4;
  }
  
  .center-slider .slick-slide {
    color: #fff;
    height: 308px;
    margin: 0;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
    width: 100%;
    border-radius: 24px !important;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    div {
      height: 100%;
      width: 100%;
    }
  }
  
  .slick-dots {
    bottom: -30px !important;
  }
  
  .slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #1a87d9 !important;
    font-size: 20px !important;
  }
  
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px !important;
    content: '•';
    text-align: center;
    opacity: 0.1 !important;
    color: #1a87d9 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  // slider end
  .populargame-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
  
  .PopularGames {
    .card {
      background: var(--Color-Light-blue, #dcf0ff80);
      border-radius: 24px;
      border: none;
      padding: 18px;
      height: 100%;
      box-shadow: 2px 2px 8px 0px #00000026;
      cursor: pointer;
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.08);
      }
      .img-box {
        max-width: 220px;
        max-height: 220px;
        border-radius: 24px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-body {
        .card-title {
          font-family: 'Roboto', sans-serif;
          font-size: var(--Spacing4);
          font-weight: 500;
        }
      }
    }
  }
  
  .OfficialStore-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  
  .official-paragraph {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    // padding-bottom: 60px;
    padding-bottom: 16px;
  }
  
  .content-icon {
    display: flex;
    .icon-box {
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .paragraph {
      h6 {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 8px;
      }
      p {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  
  .ContactUs-Heading {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }
  
  .social-card {
    padding-bottom: 45px;
    .card {
      width: 152px;
      height: 143px;
      min-width: 152px;
      min-height: 143px;
      background: #eaecf0;
      border: none;
      border-radius: 12px;
      .card-body {
        .whatsapp {
          width: 44px;
          height: 44px;
        }
        .email {
          width: 62px;
          height: 44px;
        }
        .card-title {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 400;
          padding-top: 10px;
        }
      }
    }
  }
  
  .mobile-footer {
    display: none;
  }
  
  .addcoin-modal {
    h5 {
      font-family: 'Roboto', sans-serif;
      font-size: 32px;
      font-weight: 600;
      padding-bottom: 40px;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #1a87d9;
      padding-bottom: 8px;
    }
    .input-label {
      padding-bottom: 8px;
    }
    .input-label,
    .input-box {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
    .input-box {
      height: 51px;
      background: #F9FAFB;
      border: 1px solid #EAECF0;
      &:focus {
        border: 1px solid #1A87D9;
        box-shadow: 0px 0px 0px 4px #1A87D940;
      }
    }
    button {
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 500;
      height: 62px !important;
      margin-top: 30px;
    }
  }
  
  .login-form {
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 12px;
    padding: 34px;
    // height: 650px;
    background-color: #ffffff;
    h2 {
      font-family: 'Roboto', sans-serif;
      font-size: 32px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    h6 {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 42px;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      padding-top: 16px;
      color: #00000080;
      a {
        text-decoration: none;
        color: #1a87d9;
      }
    }
    .input-box {
      height: 51px;
    }
    button {
      height: 62px;
      margin-top: 124px;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
    // line
    h5 {
      overflow: hidden;
      color: #00000080;
      margin-top: 74px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    .center-text {
      text-align: center;
    }
    .right-line:after {
      content: '';
      display: inline-block;
      height: 0.5em;
      vertical-align: bottom;
      width: 100%;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 1px solid #00000026;
    }
    .left-line:before {
      content: '';
      display: inline-block;
      height: 0.5em;
      vertical-align: bottom;
      width: 100%;
      margin-left: -100%;
      margin-right: 10px;
      border-top: 1px solid #00000026;
    }
    .PhoneInput {
      display: flex;
      align-items: center;
      .PhoneInputInput {
        height: 51px;
        border: 1px solid #eaecf0;
        background: #f9fafb;
        border-radius: 8px;
        padding-left: 15px;
        //
        color: var(--bs-body-color);
        appearance: none;
        background-clip: padding-box;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 500;
        &:focus {
          color: var(--bs-body-color);
          background-color: var(--bs-body-bg);
          border-color: #1a87d9;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
      }
      .PhoneInputCountry {
        width: 100px;
        margin-right: 26px !important;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: var(--PhoneInputCountrySelect-marginRight);
        border: 1px solid #eaecf0;
        background: #f9fafb;
        border-radius: 8px;
        .PhoneInputCountryIcon--border {
          border: none !important;
          box-shadow: none !important;
        }
        .PhoneInputCountryIcon {
          width: 24px !important;
          height: 24px !important;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          .PhoneInputCountryIconImg {
            // display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .PhoneInputCountrySelectArrow {
          display: block;
          content: '';
          margin-top: -5px;
          width: 10px;
          height: 10px;
          margin-left: 14px;
          border-style: solid;
          border-color: #000000;
          opacity: 1;
        }
      }
    }
  }
  
  .login-social {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .btn-social {
      text-decoration: none;
      border: none;
      width: 52px;
      height: 52px;
      min-width: 52px;
      min-height: 52px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      &:hover {
        opacity: 0.8;
        transition: 200ms;
      }
      img {
        width: 52px;
        height: 52px;
        object-fit: fill;
      }
    }
    .fb-btn {
      margin: 0 26px;
    }
  }
  
  .otp-input-fields {
    width: auto;
    display: flex;
    justify-content: center;
    gap: 15px;
    input {
      width: 64px !important;
      height: 72px !important;
      background-color: #f9fafb;
      border-radius: 6px;
      text-align: center;
      outline: none;
      font-size: 22px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
  
  .mobile-wrapper {
    .wrapper-login {
      padding-top: calc(116px + 8px) !important;
      min-height: 100vh !important;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .login-form {
      width: 100%;
      max-width: 528px;
    }
  }
  
  @media screen and (max-width: 720px) {
    .mobile-wrapper {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      .wrapper-login {
        min-height: auto;
        flex: 1;
        background-color: #F9FAFB;
        padding: 8px;
      }
    }
  }
  
  .nav-mobile {
    display: none;
    position: sticky;
    top: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 100;
    .login-back {
      left: 16px;
      top: 16px;
      width: 32px;
      height: 32px;
      position: absolute;
      border-radius: 4px;
      background-color: #ffffff;
      border: none;
      outline: none;
      box-shadow: 0px 0px 2px 0px #00000040;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 20px;
      }
    }
    .mobile-logo {
      max-width: 112px !important;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .footer-mobile {
    display: none;
    padding: 5px 0;
    width: 100%;
    position: relative;
    .terms-row {
      padding-bottom: 15px;
      padding-left: 8px;
      padding-right: 8px;
      a {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
        color: #00000080;
        &:hover {
          color: #000;
        }
      }
    }
    .copy-right {
      color: #00000080;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding-bottom: 10px;
      a {
        text-decoration: none;
        color: #1a87d9;
      }
    }
  }
  
  .register {
    .input-label,
    .input-box {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
    .input-box {
      height: 51px;
      background-color: #F9FAFB;
      border: 1px solid #EAECF0;
    }
    .condition {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      padding-top: 208px;
      a {
        text-decoration: none;
        color: #1A87D9;
      }
    }
    button {
      margin-top: 20px;
    }
  }
  
  .terms-content {
    font-family: "Open Sans", sans-serif;
    .TermsConditions-Heading {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }
    .terms-paragraph {
      font-size: 16px;
      font-weight: 400;
      .link {
        text-decoration: none;
        color: #212530;
      }
    }
    .Chapter-Heading {
      font-size: 28px;
      font-weight: 600;
    }
    .sub-heading {
      font-size: 24px;
      font-weight: 600;
    }
  }
  
  .wrapper-asidecontent {
    min-height: calc(100vh);
  }
  
  // aside
  aside {
    transition: 500ms;
    min-width: 224px;
    // height: calc(100vh - 116px - 24px);
    background: #fff;
    position: sticky !important;
    top: calc(116px + 24px);
    left: 0;
    // z-index: 21;
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow-y: auto;
    &.scroll-disabled {
      pointer-events: none;
    }
    strong {
      padding-left: 12px;
      pointer-events: none;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
    // =====new
    ul {
      list-style-type: none;
      // padding: 0 20px;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      .nav-link {
        text-decoration: none;
        height: 56px;
        padding-left: 15px;
        padding-right: 10px;
        // margin-top: 10px;
        // margin-bottom: 5px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        transition: 300ms;
        position: relative;
        color: #00000075;
        cursor: pointer;
        .icon {
          font-size: 24px;
        }
        &.active,
        &:hover {
          // background: #E9F2FF;
          background: #1A87D91A;
          color: #1A87D9;
        }
      }
    }
  }
  
  .profile-wrapper {
    font-family: "Roboto", sans-serif;
    .title {
      font-size: 32px;
      font-weight: 600;
    }
    form {
      width: 100%;
      max-width: 480px;
      .input-label {
        font-size: 16px;
        font-weight: 500;
      }
      .input-box {
        height: 51px;
        font-size: 16px;
        font-weight: 500;
        background-color: #F9FAFB;
        border: 1px solid #EAECF0;
        border-radius: 8px;
      }
      button {
        width: 134px;
        height: 52px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  
  // tabs
  .order-tabs {
    .nav-link {
      border: none;
      color: #00000080;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      background: none !important;
      text-wrap: nowrap;
    }
    .nav-tabs .nav-link.active {
      color: var(--bs-nav-tabs-link-active-color);
      background-color: var(--bs-nav-tabs-link-active-bg);
      border-color: var(--bs-nav-tabs-link-active-border-color);
      border-bottom: 2px solid #1a87d9;
      // outline: none;
      color: #1a87d9;
    }
    .scrollable-tabs {
      overflow-x: auto;
      scrollbar-width: none;
      /* For Firefox */
      -ms-overflow-style: none;
      /* For Internet Explorer and Edge */
    }
    .scrollable-tabs::-webkit-scrollbar {
      width: 0px;
      /* For Chrome, Safari, and Opera */
    }
    .card {
      border: 1px solid #EAECF0;
      height: 108px !important;
      border-radius: 8px;
      cursor: pointer;
      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px;
        .game-img {
          width: 52px;
          height: 52px;
          min-width: 52px;
          min-height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 8px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .order-detail {
          .game-name {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 500;
          }
          .order-complete {
            color: #39CD81;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 400;
          }
          .order-processing {
            color: #1A87D9;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 400;
          }
          .order-failed,
          .order-refunded {
            color: #DF4545;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 400;
          }
        }
        .card-subtitle {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-weight: 400;
          padding-top: 10px;
          margin: 0;
          color: #00000080;
        }
        .card-ammount {
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  
  .search-userid {
    width: 267px;
    height: 40px;
    position: relative;
    input {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #F9FAFB;
      border: 1px solid #EAECF0;
      padding-left: 14px;
      padding-right: 32px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      color: #00000080;
      font-weight: 400;
      &:focus {
        box-shadow: 0px 0px 8px 4px #1A87D940;
        border: 1px solid #1A87D9;
      }
    }
    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 50%;
      padding: 0px;
      margin: 0px;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  
  .order-completed {
    width: 100%;
    max-width: 576px;
    border-radius: 12px;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin: 0 auto;
    margin-top: 50px;
    padding: 32px;
    .icon {
      font-size: 52px;
      color: #1A87D9;
    }
    .order-title {
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 600;
      color: #1A87D9;
    }
    .puchase-title {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
    .left-title {
      text-align: start;
      color: #00000080;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    .right-title {
      text-align: right;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 500;
    }
    button {
      height: 52px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
  
  .order-detailcard {
    width: 100%;
    max-width: 544px;
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 16px;
    border-radius: 8px;
    .gameImg {
      width: 52px;
      height: 52px;
      min-width: 52px;
      min-height: 52px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .gameName {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 18.75px;
    }
    .left-order {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #00000080;
      text-align: start;
    }
    .right-order {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-align: right;
    }
    .status {
      font-weight: 500;
      color: #39CD81;
    }
    .mid-br {
      border-bottom: 1px solid #00000026;
    }
  }
  
  .order-detailbtn {
    button {
      width: 105;
      height: 52px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
  
  // 22/09/24
  .wallet {
    .title {
      font-family: "Roboto", sans-serif;
      font-size: 32px;
      font-weight: 600;
    }
    .card {
      height: 124px;
      border-radius: 12px;
      padding: 10px;
      background: linear-gradient(89.51deg, #1A87D9 0.42%, #235CC8 84.9%);
      border: none;
      color: #ffffff;
      .card-body {
        .left-coindetails {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .coin-title {
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            font-weight: 500;
          }
          .coin-value {
            font-family: "Roboto", sans-serif;
            font-size: 32px;
            font-weight: 500;
          }
          .coin-img {
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .wallet-btn {
          button {
            width: 138px;
            height: 56px;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 500;
            background-color: #ffffff;
            border: none;
            outline: none;
            color: #1A87D9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;
            .icon {
              font-size: 32px;
            }
          }
        }
      }
    }
    .transaction-title {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #00000080;
    }
  }
  
  .footer-listdrop {
    display: none;
  }
  
  // animation section
  /*animation element sliding left*/
  
  .animation-element.slide-left {
    opacity: 0;
    transition: all 2000ms linear;
    transform: translate3d(-100px, 0px, 0px);
    margin-left: -100%;
  }
  
  .animation-element.top-up {
    transition: all 2000ms linear;
    margin-left: -100%;
  }
  
  .animation-element.para {
    transition: all 2800ms linear;
    margin-left: -100%;
  }
  
  .animation-element.facility-l {
    transition: all 3800ms linear;
    margin-left: -100%;
  }
  
  .animation-element.facility {
    transition: all 3800ms linear;
    margin-left: -100%;
  }
  
  .animation-element.slide-left.in-view {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    margin-left: 0%;
  }
  
  // mobile search
  .mobile-searchfield {
    position: fixed;
    left: 0;
    margin-top: 84px;
    background-color: #fff;
    width: 100%;
    z-index: 1001;
    transition: 1000ms;
    // 
    box-shadow: 0 6px 6px -6px rgb(232, 230, 230);
    .mobile-search-field {
      width: 100% !important;
      height: 44px;
      border: 2px solid #1A87D9;
      padding-left: 50px;
      padding-right: 20px;
      background: #F9FAFB;
      display: flex;
      align-items: center;
      color: #00000080;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      &:focus {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #1A87D9;
        outline: 0;
        box-shadow: 0px 0px 8px 4px #1A87D940;
      }
    }
    .search-icon {
      top: 0;
      margin-left: 15px;
      margin-top: 10px;
      font-size: 22px;
    }
  }
  
  // floating button
  .bounce {
    position: fixed;
    right: 28px;
    bottom: 82px;
  }
  
  .float {
    width: 48px;
    height: 48px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 26px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .float-messenger {
    margin: 15px 0;
    background: rgb(95, 92, 255);
    background: linear-gradient(0deg, rgba(95, 92, 255, 1) 0%, rgba(240, 77, 148, 1) 100%);
  }
  
  .float-email {
    background-color: #1A87D9;
  }
  
  .bounce {
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-12px);
    }
  }
  
  .desktop-banner {
    display: block;
  }
  
  .mobile-banner {
    display: none;
  }
  
  // container css
  @media screen and (min-width: 992px) and (max-width: 1920px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1120px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .center-slider .slick-slide {
      height: 260px;
    }
    .PopularGames {
      .card {
        padding: 10px;
      }
    }
  }
  
  @media screen and (max-width: 992px) {
    .PopularGames {
      .card {
        .card-body {
          .card-title {
            font-size: 12px;
          }
        }
      }
    }
  }
  
  // media query
  @media only screen and (max-width: 576px) {
    .content-wrapper {
      .input-box {
        border: 1px solid #C3D6E4;
      }
    }
    .wrapper-login {
      // height: calc(100vh - 30px - 71px - 71px) !important;
      // background-color: #F9FAFB;
      padding-top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .offcanvas {
      --bs-offcanvas-width: 280px;
    }
    nav {
      .navbar-brand {
        width: 80px;
        height: 28px;
        img {
          width: 100%;
        }
      }
      .btn-login {
        width: 64px;
        height: 30px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .wrapper {
      padding-top: 128px !important;
      .question-btn {
        .icon {
          width: 20px;
          height: 20px;
        }
      }
      padding-top: 75px !important;
      .pt-32px {
        // padding-top: 4px;
        padding-top: 8.8px;
        .game-imgbox {
          height: 120px;
          max-height: 120 !important;
          border-radius: 0px;
          width: calc(100% + 16px);
          margin-left: -8px;
          img {
            border-radius: 0px;
          }
        }
        .game-content {
          padding-right: calc(var(--bs-gutter-x) * 0.5);
          padding-left: calc(var(--bs-gutter-x) * 0.5);
          .game-smimgbox {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-fit: fill;
              border-radius: 6px;
            }
          }
          .game-heading {
            font-size: 14px;
            padding-bottom: 4px;
          }
          .icon {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .details-heading {
        font-size: 16px;
      }
      .input-label {
        font-size: 14px;
      }
      .input-box {
        font-size: 14px;
        height: 44px;
      }
      .check-name {
        margin-top: 10px;
        color: #39cd81;
        .icon {
          width: 16px !important;
          height: 16px !important;
        }
        span {
          font-size: 14px;
        }
      }
      .min-h132px {
        min-height: 110px;
      }
      .payment-card {
        // min-height: 80px !important;
        min-height: 90px !important;
        .left-coin {
          .img-box {
            width: 40px;
            height: 40px;
          }
          .coin-name {
            font-size: 12px !important;
            margin-left: 5px;
          }
        }
        .right-coin {
          padding-right: 5px;
          .img-box {
            width: 16px;
            height: 16px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
          .coin-value {
            font-size: 14px;
          }
          .rupee {
            font-size: 8px;
          }
          .old-price {
            font-size: 8px;
          }
          .img-upibox {
            width: 24px;
            height: 24px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .img-upimore {
            width: 8px;
            height: 8px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 25px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .left-upi {
          display: flex;
          align-items: center;
          .img-box {
            width: 64px;
            height: 30px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
          .coin-name {
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: 500;
            margin-left: 10px;
          }
        }
      }
    }
    .cl-content {
      padding: 20px 12px !important;
      border: 1px solid #EAECF0;
    }
    .cr-badge {
      font-size: 8px !important;
    }
    .cl-badge {
      font-size: 8px !important;
    }
    .cl-title {
      h4 {
        font-size: 12px !important;
      }
      span {
        font-size: 8px !important;
      }
    }
    .cl-icon {
      width: 40px !important;
      min-width: 40px !important;
      // width: 48px;
      // min-width: 48px;
      align-self: center;
      img {
        width: 100%;
        display: block;
      }
    }
    .cl-price {
      font-size: 14px !important;
      bottom: 15px !important;
      left: 13px;
    }
    .footer-socialicon {
      h5 {
        font-size: 16px;
      }
      a {
        width: 20px;
        height: 20px;
      }
      .youtube {
        color: #ff0000;
        width: 24px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .copyright {
      font-size: 10px !important;
    }
    .detail-modal {
      p {
        font-family: 'Roboto', sans-serif;
        font-size: 8px;
        font-weight: 500;
        color: #000;
      }
      .img-details {
        width: 100%;
        height: 114px;
        border-radius: 0px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .pay-btnbox {
      position: fixed;
      left: 0;
      bottom: 0;
      background: #fff;
      // padding: 5px;
      padding: 8px;
      box-shadow: 0px 0px 4px 0px #00000040;
    }
    .btn-pay {
      height: 48px;
      font-size: 14px;
    }
    // footer {
    //   margin-bottom: 54px;
    // }
    body:has(.mobile-footer) footer {
      margin-bottom: 54px;
    }
    .dropdownList {
      position: fixed !important;
      margin-top: 0;
      left: auto;
      right: -280px;
      width: 260px;
      height: 100vh !important;
      transition: 300ms;
      &.show {
        right: 0;
      }
      .offcanvas-header {
        display: block !important;
        display: flex !important;
        justify-content: end !important;
      }
      .offcanvas-right {
        overflow-y: auto;
        height: calc(100vh - 100px);
        padding-bottom: 40px !important;
      }
      h5 {
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 8px;
      }
    }
    #columnslist {
      -moz-column-count: 1;
      // -moz-column-gap: 20px;
      -webkit-column-count: 1;
      // -webkit-column-gap: 20px;
      column-count: 1;
      // column-gap: 20px;
    }
    body:has(.language-dropdown.show) {
      .lanlist-overlay {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
    .center-slider .slick-slide {
      height: 122px;
      border-radius: 8px !important;
    }
    .slick-dots li button:before {
      font-size: 10px !important;
      content: '•';
    }
    .populargame-heading {
      font-size: 16px;
    }
    .PopularGames {
      .card {
        border-radius: 12px;
        padding: 8px;
        box-shadow: 1px 1px 4px 0px #00000026;
        .img-box {
          border-radius: 12px;
        }
        .card-body {
          padding: 0;
          // background: red;
          .card-title {
            font-size: 8px;
            padding-top: 8px;
            margin: 0;
          }
        }
      }
    }
    .OfficialStore-heading {
      font-size: 14px;
      padding-bottom: 8px;
    }
    .official-paragraph {
      font-size: 12px;
      padding-bottom: 24px;
    }
    .content-icon {
      display: flex;
      .icon-box {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 8px;
        margin-right: 8px;
      }
      .paragraph {
        h6 {
          font-size: 14px;
          padding-bottom: 8px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    .ContactUs-Heading {
      font-size: 16px;
      text-align: center;
    }
    .social-card {
      padding-bottom: 24px;
      .card {
        width: 104px;
        height: 94px;
        min-width: 104px;
        min-height: 94px;
        border-radius: 8px;
        .card-body {
          .whatsapp {
            width: 32px;
            height: 32px;
          }
          .email {
            width: 44px;
            height: 32px;
          }
          .card-title {
            font-size: 14px;
            padding-top: 6px;
          }
        }
      }
    }
    .mobile-footer {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-shadow: 0px 0px 4px 0px #00000040;
      height: 56px;
      .ft-btn {
        text-align: center;
        color: #00000075;
        transition: 500ms;
        .icon {
          font-size: 18px;
        }
        p {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-weight: 500;
        }
        &:hover {
          color: #1a87d9;
        }
        &.active {
          color: #1a87d9;
          .icon-bg {
            border-radius: 25px;
            padding: 4px;
            width: 42px;
            max-width: 42px;
            background: #1A87D91A;
            transition: 500ms;
          }
        }
      }
    }
    .btn-coin {
      min-width: 100px;
      // width: 100px;
      height: 30px;
      border: 1px solid #eaecf0;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
      &:hover,
       :active {
        opacity: 0.7;
      }
      img {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
      span {
        font-size: 14px;
      }
    }
    .use-btn {
      min-width: 68px;
      // width: 68px;
      max-width: 94px;
      height: 30px;
      span {
        padding-left: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .addcoin-modal {
      .modal-header {
        padding-bottom: 0px !important;
        .btn-close {
          padding-bottom: 0px;
        }
      }
      h5 {
        font-size: 24px;
        padding-bottom: 24px;
        margin-top: -12px;
      }
      p {
        font-size: 14px;
      }
      .input-label,
      .input-box {
        font-size: 14px;
      }
      .input-box {
        height: 44px;
        &:focus {
          box-shadow: 0px 0px 0px 3px #1A87D940;
        }
      }
      button {
        font-size: 16px;
        height: 44px !important;
        margin-top: 24px;
      }
    }
    .login-form {
      border-radius: 8px;
      padding: 14px;
      border: 1px solid #EAECF0;
      box-shadow: none;
      // height: 460px !important;
      h2 {
        font-size: 24px;
        padding-bottom: 14px;
      }
      h6 {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .input-box {
        height: 44px;
      }
      button {
        height: 44px;
        margin-top: 116px;
        font-size: 16px;
      }
      // line
      h5 {
        overflow: hidden;
        color: #00000080;
        margin-top: 62px;
      }
      p {
        font-size: 14px;
        padding-top: 10px;
      }
      .center-text {
        text-align: center;
      }
      .right-line:after {
        content: '';
        display: inline-block;
        height: 0.5em;
        vertical-align: bottom;
        width: 100%;
        margin-right: -100%;
        margin-left: 10px;
        border-top: 1px solid #00000026;
      }
      .left-line:before {
        content: '';
        display: inline-block;
        height: 0.5em;
        vertical-align: bottom;
        width: 100%;
        margin-left: -100%;
        margin-right: 10px;
        border-top: 1px solid #00000026;
      }
      .PhoneInput {
        display: flex;
        align-items: center;
        .PhoneInputInput {
          height: 44px;
          padding-left: 10px;
          font-size: 14px;
        }
        .PhoneInputCountry {
          width: 84px;
          margin-right: 10px !important;
          .PhoneInputCountryIcon {
            width: 20px !important;
            height: 20px !important;
            .PhoneInputCountryIconImg {
              // display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .PhoneInputCountrySelectArrow {
            display: block;
            content: '';
            width: 8px;
            height: 8px;
            margin-left: 12px;
          }
        }
      }
    }
    .login-social {
      margin-top: 22px;
      .btn-social {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 50%;
        img {
          width: 40px;
          height: 40px;
          object-fit: fill;
        }
      }
      .fb-btn {
        margin: 0 26px;
      }
    }
    .otp-input-fields {
      gap: 10px;
      input {
        width: 42px !important;
        height: 48px !important;
        font-size: 16px;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
    .mainheaderFooter {
      display: none;
    }
    .nav-mobile {
      display: block;
      background-color: #F9FAFB;
    }
    .footer-mobile {
      display: block;
      background-color: #F9FAFB;
    }
    .country-btn {
      span {
        font-size: 14px !important;
        font-weight: 400;
      }
      img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
    }
    .slick-dots li.slick-active button:before {
      font-size: 16px !important;
    }
    .slick-dots li button:before {
      font-size: 12px !important;
    }
    .register {
      .input-label,
      .input-box {
        font-size: 14px;
      }
      .input-box {
        height: 44px;
      }
      .condition {
        font-size: 14px;
        padding-top: 124px;
        a {
          text-decoration: none;
          color: #1A87D9;
        }
      }
      button {
        margin-top: 15px;
        height: 44px;
      }
    }
    .terms-content {
      .TermsConditions-Heading {
        font-size: 24px;
      }
      .terms-paragraph {
        font-size: 12px;
      }
      .Chapter-Heading {
        font-size: 16px;
      }
      .sub-heading {
        font-size: 14px;
      }
    }
    .profile-wrapper {
      font-family: "Roboto", sans-serif;
      .title {
        font-size: 24px;
      }
      form {
        width: 100% !important;
        min-width: 100%;
        .input-label {
          font-size: 14px;
        }
        .input-box {
          height: 44px;
          font-size: 14px;
        }
        button {
          width: 100%;
          height: 44px;
          font-size: 16px;
        }
      }
    }
    .order-tabs {
      .nav-link {
        font-size: 14px;
      }
      .card {
        border: 1px solid #EAECF0;
        height: 82px !important;
        border-radius: 8px;
        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px;
          .game-img {
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
            border-radius: 6px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .order-detail {
            .game-name {
              font-size: 14px;
            }
            .order-complete {
              font-size: 14px;
            }
            .order-processing {
              font-size: 14px;
            }
            .order-failed,
            .order-refunded {
              font-size: 14px;
            }
          }
          .card-subtitle {
            font-size: 12px;
            padding-top: 0px;
          }
          .card-ammount {
            font-size: 16px;
          }
        }
      }
    }
    .search-userid {
      width: 220px;
      height: 32px;
      position: relative;
      input {
        padding-left: 8px;
        font-size: 14px;
      }
      button {
        top: 5px;
        right: 5px;
        width: 22px;
        height: 22px;
      }
    }
    .order-completed {
      width: 100%;
      max-width: 576px;
      box-shadow: none;
      margin-top: 16px;
      padding: 16px;
      .icon {
        // font-size: 32px;
        // font-size: 64px;
        font-size: 48px;
        color: #1A87D9;
      }
      .order-title {
        font-size: 16px;
      }
      .puchase-title {
        font-size: 14px;
      }
      .left-title {
        font-size: 12px;
      }
      .right-title {
        font-size: 12px;
      }
      button {
        height: 44px;
      }
    }
    .order-detailcard {
      width: 100%;
      max-width: 544px;
      box-shadow: none;
      padding: 0px;
      border-radius: 8px;
      .gameImg {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        border-radius: 6px;
      }
      .gameName {
        font-size: 14px;
        line-height: 16.41px;
      }
      .left-order {
        font-size: 12px;
      }
      .right-order {
        font-size: 12px;
      }
      .start-br {
        border-bottom: 1px solid #00000026;
      }
      .mid-br {
        border-bottom: 1px solid #00000026;
      }
      .last-br {
        border-bottom: 1px solid #00000026;
      }
    }
    .order-detailbtn {
      button {
        width: 100%;
        height: 44px;
      }
    }
    .wallet {
      .title {
        font-size: 24px;
      }
      .card {
        height: 80px;
        border-radius: 12px;
        padding: 8px;
        background: linear-gradient(89.51deg, #1A87D9 0.42%, #235CC8 84.9%);
        border: none;
        color: #ffffff;
        .card-body {
          .left-coindetails {
            height: 100% !important;
            height: 46px !important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .coin-title {
              font-size: 12px;
            }
            .coin-value {
              font-size: 24px;
            }
            .coin-img {
              width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .wallet-btn {
            button {
              width: 105px;
              height: 36px;
              font-size: 14px;
              padding: 0 8px;
              .icon {
                font-size: 24px;
              }
            }
          }
        }
      }
      .transaction-title {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #00000080;
      }
    }
    footer {
      h6 {
        font-size: 20px;
        pointer-events: all;
        margin-bottom: 0px;
        transition: 300ms;
        &.collapse-mobile {
          // border-bottom: 1px dashed #555;
          &[aria-expanded=true] {
            // color: orange;
            border-bottom: 1px solid transparent;
            border: none;
            &::after {
              margin-top: 7px;
              transform: rotate(-135deg);
            }
          }
          &::after {
            display: block;
          }
        }
      }
      .collapse:not(.show) {
        display: none;
      }
      .navbar-brand {
        height: 48pxn !important;
        img {
          height: 100%;
        }
      }
    }
    .desktop-banner {
      display: none;
    }
    .mobile-banner {
      display: block;
    }
  }
  
  // grid css
  @media screen and (min-width: 992px) {
    .col-lg-340 {
      max-width: 340px;
    }
  }
  
  @media screen and (min-width: 767px) {
    .col-md-340 {
      max-width: 340px;
    }
  }
  
  @media screen and (min-width: 575px) {
    .col-sm-340 {
      max-width: 340px;
    }
  }
  
  // com..
  .input-box {
    &:focus {
      border: 1px solid #1A87D9 !important;
      box-shadow: 0px 0px 0px 4px #1A87D940 !important;
    }
  }