@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.l {
  -webkit-box-shadow: inset 0 0 0 1px red;
  box-shadow: inset 0 0 0 1px red;
}

.icon {
  stroke: none;
  color: currentColor;
  fill: currentColor;
  width: 1em;
  height: 1em;
}

.g-10px,
.gx-10px {
  --bs-gutter-x: 10px;
}

.g-10px,
.gy-10px {
  --bs-gutter-y: 10px;
}

.Robotoff {
  font-family: 'Roboto', sans-serif;
}

.Poppinsff {
  font-family: 'Poppins', sans-serif;
}

.Interff {
  font-family: 'Inter', sans-serif;
}

/* Firefox */
* {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #1a87d9;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.lh-md {
  line-height: 1.5 !important;
}

.maxw-900 {
  max-width: 900px;
}

.maxw-1000 {
  max-width: 1000px;
}

.maxw-1100 {
  max-width: 1100px;
}

.maxw-600 {
  max-width: 600px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-5p2 {
  font-size: 1.375rem !important;
}

.fs-4p2 {
  font-size: 2.375rem !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800;
}

.pt-32px {
  padding-top: 32px;
}

nav {
  padding: 0.5rem 0;
}

nav .navbar-brand {
  width: 163px;
  height: 58px;
  max-height: 58px;
}

nav .navbar-brand img {
  width: 100%;
}

nav .search-icon {
  top: 0;
  margin-left: 15px;
  margin-top: 10px;
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 4px 0px #00000040;
  box-shadow: 0px 0px 4px 0px #00000040;
}

header .btn-login {
  width: 92px;
  height: 44px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header .country-box .country-btn {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
}

header .country-box .country-btn:hover,
header .country-box .country-btn :active {
  opacity: 0.7;
}

header .country-box .country-btn .icon {
  -webkit-transition: 300ms;
  transition: 300ms;
}

header .country-box .country-btn[aria-expanded='true'] .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

header .country-box .country-btn span {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

header .country-box .country-btn img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

header .country-box .country-btn .icon {
  font-size: 12px;
}

.btn-coin {
  min-width: 124px;
  height: 44px;
  border: 1px solid #eaecf0;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
}

.btn-coin:hover,
.btn-coin :active {
  opacity: 0.7;
}

.btn-coin img {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
}

.btn-coin span {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.use-btn {
  min-width: 84px;
  max-width: 118px;
  height: 44px;
}

.use-btn span {
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownList {
  position: fixed;
  left: 0;
  margin-top: 115.5px;
  background-color: #fff;
  width: 100%;
  z-index: 1002;
}

.dropdownList .country-list {
  padding-bottom: 16px;
}

.dropdownList .country-list .offcanvas-right {
  list-style-type: none;
  padding: 0 0px;
  margin: 0;
}

.dropdownList .country-list .offcanvas-right .nav-link {
  text-decoration: none;
  height: 44px;
  padding-left: 15px;
  padding-right: 10px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.dropdownList .country-list .offcanvas-right .nav-link.active,
.dropdownList .country-list .offcanvas-right .nav-link:hover {
  background: #1a87d91a;
}

.dropdownList .country-list .offcanvas-right .nav-link strong {
  padding-left: 8px;
  pointer-events: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.dropdownList .offcanvas-header {
  display: none !important;
}

.dropdownList h5 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 16px;
}

#columnslist {
  -webkit-column-count: 2;
  column-count: 2;
}

.navbar .navbar-toggler-icon {
  font-weight: 800;
}

.navbar .navbar-nav {
  padding: 0;
  font-family: 'Inter', sans-serif;
}

.navbar .navbar-nav .nav-link {
  color: #000;
  border-bottom: 2px solid transparent;
}

.navbar .navbar-nav .nav-link:hover {
  border-bottom: 2px solid #1a87d9;
  outline: none;
  color: #00000080;
}

.navbar .navbar-nav .nav-link.active {
  border-bottom: 2px solid #1a87d9;
}

.wrapper {
  padding-top: 116px !important;
}

.product {
  background-color: #eff1f5;
}

.game-imgbox {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
}

.game-imgbox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 8px;
}

.game-content .game-smimgbox {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  overflow: hidden;
}

.game-content .game-smimgbox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 8px;
}

.game-content .game-heading {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.game-content .icon {
  width: 16px;
  height: 16px;
}

.game-content span {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

::-webkit-input-placeholder {
  color: #00000080;
  opacity: 50% !important;
  /* Firefox */
}

:-ms-input-placeholder {
  color: #00000080;
  opacity: 50% !important;
  /* Firefox */
}

::-ms-input-placeholder {
  color: #00000080;
  opacity: 50% !important;
  /* Firefox */
}

::placeholder {
  color: #00000080;
  opacity: 50% !important;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #00000080;
}

.content-wrapper .details-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.content-wrapper .question-btn {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: none;
  margin-left: 10px;
  margin-top: -3px;
}

.content-wrapper .question-btn .icon {
  width: 24px;
  height: 24px;
}

.content-wrapper .input-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.content-wrapper .input-box {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #c3d6e4;
  height: 56px;
  background: #f9fafb;
}

.content-wrapper .form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #1a87d9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.content-wrapper .check-name {
  margin-top: 10px;
  color: #39cd81;
}

.content-wrapper .check-name .icon {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
}

.content-wrapper .check-name span {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.btn-login {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1a87d9;
  --bs-btn-border-color: #1a87d9;
  --bs-btn-hover-color: #1a87d9;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #1a87d9;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1a87d9;
  --bs-btn-disabled-border-color: #1a87d9;
  border: 2px solid #1a87d9;
}

.search-field {
  border: 2px solid #1a87d9;
  padding-left: 50px;
  padding-right: 20px;
  width: 320px;
  max-width: 320px;
  height: 44px;
  background: #f9fafb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #00000080;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.search-field:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0px 0px 8px 4px rgba(26, 135, 217, 0.25);
  box-shadow: 0px 0px 8px 4px rgba(26, 135, 217, 0.25);
}

.card-label {
  display: block;
  position: relative;
  cursor: pointer;
}

.card-label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.card-label input:checked ~ .cl-content {
  background: #1a87d91a;
  -webkit-box-shadow: inset 0 0 0 2px #1a87d9;
  box-shadow: inset 0 0 0 2px #1a87d9;
}

.card-label input:checked ~ .cl-content::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.card-label input:checked ~ .cl-content::before,
.card-label input:checked ~ .cl-content::after {
  visibility: visible;
}

.card-label .cl-content {
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 22px 15px;
  padding: 22px 15px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 2px solid #eaecf0;
}

.card-label .cl-content::before {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1.4rem 1.4rem;
  background-position: 20px 4px;
  top: 0;
  right: 0;
  z-index: 1;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  visibility: hidden;
  -webkit-transition: 400ms;
  transition: 400ms;
}

.card-label .cl-content::after {
  content: '';
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #1a87d9;
  top: -32px;
  right: -32px;
  z-index: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  visibility: hidden;
}

.card-label .cl-content .cl-badge {
  position: absolute;
  top: 0;
  left: -2px;
  height: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1;
  background: red;
  color: #fff;
  padding: 0 12px;
  border-radius: 0 0 5px 0;
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

.card-label .cl-content .cl-badge small {
  display: block;
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.card-label .cl-content .cr-badge {
  position: absolute;
  bottom: 0;
  right: -2px;
  height: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1;
  background: red;
  color: #fff;
  padding: 0 12px;
  border-radius: 5px 0 0 2px;
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

.card-label .cl-content .cr-badge small {
  display: block;
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.card-label .cl-content .cl-title {
  width: 100%;
  padding-right: 8px;
  font-family: 'Roboto', sans-serif;
}

.card-label .cl-content .cl-title h4 {
  font-size: 16px;
  font-weight: 400;
}

.card-label .cl-content .cl-title span {
  font-size: 12px;
  font-weight: 400;
}

.card-label .cl-content .cl-icon {
  width: 64px;
  min-width: 54px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.card-label .cl-content .cl-icon img {
  width: 100%;
  display: block;
}

.card-label .cl-content .cl-price {
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  bottom: 20px;
  left: 16px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.card-label .payment-card {
  min-height: 116px !important;
}

.card-label .payment-card .left-coin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-label .payment-card .left-coin .img-box {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-label .payment-card .left-coin .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.card-label .payment-card .left-coin .coin-name {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.card-label .payment-card .right-coin .img-box {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-label .payment-card .right-coin .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.card-label .payment-card .right-coin .coin-value {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.card-label .payment-card .right-coin .rupee {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.card-label .payment-card .right-coin .old-price {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #00000080;
}

.card-label .payment-card .right-coin .img-upibox {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 14px;
}

.card-label .payment-card .right-coin .img-upibox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.card-label .payment-card .right-coin .img-upimore {
  width: 8px;
  height: 8px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 25px;
}

.card-label .payment-card .right-coin .img-upimore img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.card-label .payment-card .left-upi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-label .payment-card .left-upi .img-box {
  width: 64px;
  height: 30px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-label .payment-card .left-upi .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.card-label .payment-card .left-upi .coin-name {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.min-h132px {
  min-height: 132px;
}

footer {
  background: #111215;
}

footer h6 {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

footer h6::after {
  content: '';
  width: 10px;
  height: 10px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
  -webkit-transition: 300ms;
  transition: 300ms;
}

footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

footer ul li {
  padding-bottom: 8px;
}

footer ul li a {
  text-decoration: none;
  color: #a9a9a9;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

footer ul li a:hover {
  color: #fff;
  text-decoration: underline;
}

footer .download-app {
  width: 216px;
  height: 112px;
  background: #50505080;
  border-radius: 8px;
}

footer .download-app .white-box {
  width: 88px;
  height: 88px;
  border-radius: 8px;
  background-color: #ffffff;
  text-decoration: none;
}

footer .download-app .game-box {
  width: 62px;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
}

footer .download-app .game-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

footer .copyright {
  border-top: 1px solid #a9a9a9;
  color: #a9a9a9;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

footer .footer-socialicon h5 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

footer .footer-socialicon a {
  width: 24px;
  height: 24px;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 20px;
}

footer .footer-socialicon a:last-child {
  margin-right: 0px;
}

footer .footer-socialicon a img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

footer .footer-socialicon .youtube {
  color: #ff0000;
  width: 28px;
  height: 20px;
}

footer .footer-socialicon .youtube img {
  width: 28px;
  height: 28px;
}

footer .collapse:not(.show) {
  display: block;
}

.detail-modal p {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.detail-modal .img-details {
  width: 100%;
  height: 190px;
  border-radius: 0px;
  overflow: hidden;
}

.detail-modal .img-details img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px;
  width: 32px;
  height: 30px;
}

.back-btn .icon {
  width: 20px;
  height: 22px;
}

.navbar-toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-close:focus {
  /* box-shadow: var(--bs-btn-close-focus-shadow); */
  -webkit-box-shadow: none;
  box-shadow: none;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

.btn-pay {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1a87d9;
  --bs-btn-border-color: #1a87d9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #156baf;
  --bs-btn-hover-border-color: #156baf;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #156baf;
  --bs-btn-active-border-color: #156baf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1a87d9;
  --bs-btn-disabled-border-color: #1a87d9;
  height: 64px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.offcanvas-logo {
  width: 80px;
  height: 28px;
  overflow: hidden;
}

.offcanvas-logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.offcanvas-header {
  -webkit-box-shadow: 0px 0px 4px 0px #00000040;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.offcanvas-body {
  padding: 0px 8px;
}

.offcanvas-body .offcanvas-aside {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.offcanvas-body .offcanvas-aside .nav-link {
  text-decoration: none;
  height: 44px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.offcanvas-body .offcanvas-aside .nav-link:hover {
  background: #1a87d91a;
}

.offcanvas-body .offcanvas-aside .nav-link span .icon {
  width: 24px;
  height: 24px;
}

.offcanvas-body .offcanvas-aside .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.offcanvas-body button {
  position: absolute;
  left: 20px;
  bottom: 20px;
  -webkit-box-shadow: 0px 0px 4px 0px #00000040;
  box-shadow: 0px 0px 4px 0px #00000040;
  outline: none;
  width: 105px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: #fff;
}

.offcanvas-body button .icon {
  width: 20px;
  height: 20px;
}

.offcanvas-body button:hover,
.offcanvas-body button :active {
  background-color: #1a87d91a;
}

.lanlist-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 15, 15, 0.594);
  z-index: 1001;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 300ms;
  transition: 300ms;
}

/* Default slide */
.center-slider {
  padding-top: 30px;
  padding-bottom: 30px;
}

.slick-slide:not(.slick-center) {
  -webkit-transform: scale(0.95, 0.75);
  transform: scale(0.95, 0.75);
  opacity: 0.4;
}

.center-slider .slick-slide {
  color: #fff;
  height: 308px;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 24px !important;
  overflow: hidden;
}

.center-slider .slick-slide img {
  width: 100%;
  height: 100%;
}

.center-slider .slick-slide div {
  height: 100%;
  width: 100%;
}

.slick-dots {
  bottom: -30px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #1a87d9 !important;
  font-size: 20px !important;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 16px !important;
  content: '•';
  text-align: center;
  opacity: 0.1 !important;
  color: #1a87d9 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.populargame-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.PopularGames .card {
  background: var(--Color-Light-blue, #dcf0ff80);
  border-radius: 24px;
  border: none;
  padding: 18px;
  height: 100%;
  -webkit-box-shadow: 2px 2px 8px 0px #00000026;
  box-shadow: 2px 2px 8px 0px #00000026;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.PopularGames .card:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.PopularGames .card .img-box {
  max-width: 220px;
  max-height: 220px;
  border-radius: 24px;
  overflow: hidden;
}

.PopularGames .card .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.PopularGames .card .card-body .card-title {
  font-family: 'Roboto', sans-serif;
  font-size: var(--Spacing4);
  font-weight: 500;
}

.OfficialStore-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 16px;
}

.official-paragraph {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 16px;
}

.content-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.content-icon .icon-box {
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 16px;
}

.content-icon .icon-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.content-icon .paragraph h6 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
}

.content-icon .paragraph p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.ContactUs-Heading {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.social-card {
  padding-bottom: 45px;
}

.social-card .card {
  width: 152px;
  height: 143px;
  min-width: 152px;
  min-height: 143px;
  background: #eaecf0;
  border: none;
  border-radius: 12px;
}

.social-card .card .card-body .whatsapp {
  width: 44px;
  height: 44px;
}

.social-card .card .card-body .email {
  width: 62px;
  height: 44px;
}

.social-card .card .card-body .card-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 10px;
}

.mobile-footer {
  display: none;
}

.addcoin-modal h5 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 40px;
}

.addcoin-modal p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1a87d9;
  padding-bottom: 8px;
}

.addcoin-modal .input-label {
  padding-bottom: 8px;
}

.addcoin-modal .input-label,
.addcoin-modal .input-box {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.addcoin-modal .input-box {
  height: 51px;
  background: #f9fafb;
  border: 1px solid #eaecf0;
}

.addcoin-modal .input-box:focus {
  border: 1px solid #1a87d9;
  -webkit-box-shadow: 0px 0px 0px 4px #1a87d940;
  box-shadow: 0px 0px 0px 4px #1a87d940;
}

.addcoin-modal button {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 62px !important;
  margin-top: 30px;
}

.login-form {
  -webkit-box-shadow: 0px 0px 4px 0px #00000040;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 12px;
  padding: 34px;
  background-color: #ffffff;
}

.login-form h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 20px;
}

.login-form h6 {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 42px;
}

.login-form p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 16px;
  color: #00000080;
}

.login-form p a {
  text-decoration: none;
  color: #1a87d9;
}

.login-form .input-box {
  height: 51px;
}

.login-form button {
  height: 62px;
  margin-top: 124px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.login-form h5 {
  overflow: hidden;
  color: #00000080;
  margin-top: 74px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.login-form .center-text {
  text-align: center;
}

.login-form .right-line:after {
  content: '';
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #00000026;
}

.login-form .left-line:before {
  content: '';
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-left: -100%;
  margin-right: 10px;
  border-top: 1px solid #00000026;
}

.login-form .PhoneInput {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-form .PhoneInput .PhoneInputInput {
  height: 51px;
  border: 1px solid #eaecf0;
  background: #f9fafb;
  border-radius: 8px;
  padding-left: 15px;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.login-form .PhoneInput .PhoneInputInput:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #1a87d9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.login-form .PhoneInput .PhoneInputCountry {
  width: 100px;
  margin-right: 26px !important;
  position: relative;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  border: 1px solid #eaecf0;
  background: #f9fafb;
  border-radius: 8px;
}

.login-form .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon--border {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.login-form .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-form .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon .PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.login-form .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  margin-top: -5px;
  width: 10px;
  height: 10px;
  margin-left: 14px;
  border-style: solid;
  border-color: #000000;
  opacity: 1;
}

.login-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 40px;
}

.login-social .btn-social {
  text-decoration: none;
  border: none;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.login-social .btn-social:hover {
  opacity: 0.8;
  -webkit-transition: 200ms;
  transition: 200ms;
}

.login-social .btn-social img {
  width: 52px;
  height: 52px;
  -o-object-fit: fill;
  object-fit: fill;
}

.login-social .fb-btn {
  margin: 0 26px;
}

.otp-input-fields {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 15px;
}

.otp-input-fields input {
  width: 64px !important;
  height: 72px !important;
  background-color: #f9fafb;
  border-radius: 6px;
  text-align: center;
  outline: none;
  font-size: 22px;
  /* Firefox */
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-fields input[type='number'] {
  -moz-appearance: textfield;
}

.mobile-wrapper .wrapper-login {
  /* padding-top: calc(116px + 8px) !important; */
  /* min-height: 100vh !important; */
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mobile-wrapper .login-form {
  width: 100%;
  max-width: 528px;
}

@media screen and (max-width: 720px) {
  .mobile-wrapper {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .mobile-wrapper .wrapper-login {
    min-height: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #f9fafb;
    padding: 8px;
  }
}

.nav-mobile {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 100;
}

.nav-mobile .login-back {
  left: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  position: absolute;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  outline: none;
  -webkit-box-shadow: 0px 0px 2px 0px #00000040;
  box-shadow: 0px 0px 2px 0px #00000040;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-mobile .login-back .icon {
  font-size: 20px;
}

.nav-mobile .mobile-logo {
  max-width: 112px !important;
  height: 40px;
}

.nav-mobile .mobile-logo img {
  width: 100%;
  height: 100%;
}

.footer-mobile {
  display: none;
  padding: 5px 0;
  width: 100%;
  position: relative;
}

.footer-mobile .terms-row {
  padding-bottom: 15px;
  padding-left: 8px;
  padding-right: 8px;
}

.footer-mobile .terms-row a {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #00000080;
}

.footer-mobile .terms-row a:hover {
  color: #000;
}

.footer-mobile .copy-right {
  color: #00000080;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 10px;
}

.footer-mobile .copy-right a {
  text-decoration: none;
  color: #1a87d9;
}

.register .input-label,
.register .input-box {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.register .input-box {
  height: 51px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
}

.register .condition {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 208px;
}

.register .condition a {
  text-decoration: none;
  color: #1a87d9;
}

.register button {
  margin-top: 20px;
}

.terms-content {
  font-family: 'Open Sans', sans-serif;
}

.terms-content .TermsConditions-Heading {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.terms-content .terms-paragraph {
  font-size: 16px;
  font-weight: 400;
}

.terms-content .terms-paragraph .link {
  text-decoration: none;
  color: #212530;
}

.terms-content .Chapter-Heading {
  font-size: 28px;
  font-weight: 600;
}

.terms-content .sub-heading {
  font-size: 24px;
  font-weight: 600;
}

.wrapper-asidecontent {
  min-height: calc(100vh);
}

aside {
  -webkit-transition: 500ms;
  transition: 500ms;
  min-width: 224px;
  background: #fff;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: calc(116px + 24px);
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  overflow-y: auto;
}

aside.scroll-disabled {
  pointer-events: none;
}

aside strong {
  padding-left: 12px;
  pointer-events: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

aside ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

aside ul .nav-link {
  text-decoration: none;
  height: 56px;
  padding-left: 15px;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  position: relative;
  color: #00000075;
  cursor: pointer;
}

aside ul .nav-link .icon {
  font-size: 24px;
}

aside ul .nav-link.active,
aside ul .nav-link:hover {
  background: #1a87d91a;
  color: #1a87d9;
}

.profile-wrapper {
  font-family: 'Roboto', sans-serif;
}

.profile-wrapper .title {
  font-size: 32px;
  font-weight: 600;
}

.profile-wrapper form {
  width: 100%;
  max-width: 480px;
}

.profile-wrapper form .input-label {
  font-size: 16px;
  font-weight: 500;
}

.profile-wrapper form .input-box {
  height: 51px;
  font-size: 16px;
  font-weight: 500;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.profile-wrapper form button {
  width: 134px;
  height: 52px;
  font-size: 16px;
  font-weight: 500;
}

.order-tabs .nav-link {
  border: none;
  color: #00000080;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  background: none !important;
  text-wrap: nowrap;
}

.order-tabs .nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border-bottom: 2px solid #1a87d9;
  color: #1a87d9;
}

.order-tabs .scrollable-tabs {
  overflow-x: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.order-tabs .scrollable-tabs::-webkit-scrollbar {
  width: 0px;
  /* For Chrome, Safari, and Opera */
}

.order-tabs .card {
  border: 1px solid #eaecf0;
  height: 108px !important;
  border-radius: 8px;
  cursor: pointer;
}

.order-tabs .card .card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px;
}

.order-tabs .card .card-body .game-img {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
}

.order-tabs .card .card-body .game-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.order-tabs .card .card-body .order-detail .game-name {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.order-tabs .card .card-body .order-detail .order-complete {
  color: #39cd81;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.order-tabs .card .card-body .order-detail .order-processing {
  color: #1a87d9;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.order-tabs .card .card-body .order-detail .order-failed,
.order-tabs .card .card-body .order-detail .order-refunded {
  color: #df4545;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.order-tabs .card .card-body .card-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  margin: 0;
  color: #00000080;
}

.order-tabs .card .card-body .card-ammount {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  padding: 0;
  margin: 0;
}

.search-userid {
  width: 267px;
  height: 40px;
  position: relative;
}

.search-userid input {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  padding-left: 14px;
  padding-right: 32px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #00000080;
  font-weight: 400;
}

.search-userid input:focus {
  -webkit-box-shadow: 0px 0px 8px 4px #1a87d940;
  box-shadow: 0px 0px 8px 4px #1a87d940;
  border: 1px solid #1a87d9;
}

.search-userid button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.search-userid button img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.order-completed {
  width: 100%;
  max-width: 576px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 0px 4px 0px #00000040;
  box-shadow: 0px 0px 4px 0px #00000040;
  margin: 0 auto;
  margin-top: 50px;
  padding: 32px;
}

.order-completed .icon {
  font-size: 52px;
  color: #1a87d9;
}

.order-completed .order-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #1a87d9;
}

.order-completed .puchase-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.order-completed .left-title {
  text-align: start;
  color: #00000080;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.order-completed .right-title {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.order-completed button {
  height: 52px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.order-detailcard {
  width: 100%;
  max-width: 544px;
  -webkit-box-shadow: 0px 0px 4px 0px #00000040;
  box-shadow: 0px 0px 4px 0px #00000040;
  padding: 16px;
  border-radius: 8px;
}

.order-detailcard .gameImg {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  border-radius: 8px;
  overflow: hidden;
}

.order-detailcard .gameImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.order-detailcard .gameName {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
}

.order-detailcard .left-order {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #00000080;
  text-align: start;
}

.order-detailcard .right-order {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
}

.order-detailcard .status {
  font-weight: 500;
  color: #39cd81;
}

.order-detailcard .mid-br {
  border-bottom: 1px solid #00000026;
}

.order-detailbtn button {
  width: 105;
  height: 52px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.wallet .title {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.wallet .card {
  height: 124px;
  border-radius: 12px;
  padding: 10px;
  background: linear-gradient(89.51deg, #1a87d9 0.42%, #235cc8 84.9%);
  border: none;
  color: #ffffff;
}

.wallet .card .card-body .left-coindetails {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.wallet .card .card-body .left-coindetails .coin-title {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.wallet .card .card-body .left-coindetails .coin-value {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 500;
}

.wallet .card .card-body .left-coindetails .coin-img {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wallet .card .card-body .left-coindetails .coin-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.wallet .card .card-body .wallet-btn button {
  width: 138px;
  height: 56px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff;
  border: none;
  outline: none;
  color: #1a87d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 12px;
}

.wallet .card .card-body .wallet-btn button .icon {
  font-size: 32px;
}

.wallet .transaction-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #00000080;
}

.footer-listdrop {
  display: none;
}

/*animation element sliding left*/
.animation-element.slide-left {
  opacity: 0;
  -webkit-transition: all 2000ms linear;
  transition: all 2000ms linear;
  -webkit-transform: translate3d(-100px, 0px, 0px);
  transform: translate3d(-100px, 0px, 0px);
  margin-left: -100%;
}

.animation-element.top-up {
  -webkit-transition: all 2000ms linear;
  transition: all 2000ms linear;
  margin-left: -100%;
}

.animation-element.para {
  -webkit-transition: all 2800ms linear;
  transition: all 2800ms linear;
  margin-left: -100%;
}

.animation-element.facility-l {
  -webkit-transition: all 3800ms linear;
  transition: all 3800ms linear;
  margin-left: -100%;
}

.animation-element.facility {
  -webkit-transition: all 3800ms linear;
  transition: all 3800ms linear;
  margin-left: -100%;
}

.animation-element.slide-left.in-view {
  opacity: 1;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  margin-left: 0%;
}

.mobile-searchfield {
  position: fixed;
  left: 0;
  margin-top: 84px;
  background-color: #fff;
  width: 100%;
  z-index: 1001;
  -webkit-transition: 1000ms;
  transition: 1000ms;
  -webkit-box-shadow: 0 6px 6px -6px #e8e6e6;
  box-shadow: 0 6px 6px -6px #e8e6e6;
}

.mobile-searchfield .mobile-search-field {
  width: 100% !important;
  height: 44px;
  border: 2px solid #1a87d9;
  padding-left: 50px;
  padding-right: 20px;
  background: #f9fafb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #00000080;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.mobile-searchfield .mobile-search-field:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #1a87d9;
  outline: 0;
  -webkit-box-shadow: 0px 0px 8px 4px #1a87d940;
  box-shadow: 0px 0px 8px 4px #1a87d940;
}

.mobile-searchfield .search-icon {
  top: 0;
  margin-left: 15px;
  margin-top: 10px;
  font-size: 22px;
}

.bounce {
  position: fixed;
  right: 28px;
  bottom: 82px;
}

.float {
  width: 48px;
  height: 48px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 26px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.float-messenger {
  margin: 15px 0;
  background: #5f5cff;
  background: -webkit-gradient(linear, left bottom, left top, from(#5f5cff), to(#f04d94));
  background: linear-gradient(0deg, #5f5cff 0%, #f04d94 100%);
}

.float-email {
  background-color: #1a87d9;
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

.desktop-banner {
  display: block;
}

.mobile-banner {
  display: none;
}

@media screen and (min-width: 992px) and (max-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1120px;
  }
}

@media screen and (max-width: 992px) {
  .center-slider .slick-slide {
    height: 260px;
  }
  .PopularGames .card {
    padding: 10px;
  }
}

@media screen and (max-width: 992px) {
  .PopularGames .card .card-body .card-title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 576px) {
  .content-wrapper .input-box {
    border: 1px solid #c3d6e4;
  }
  .wrapper-login {
    padding-top: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .offcanvas {
    --bs-offcanvas-width: 280px;
  }
  nav .navbar-brand {
    width: 80px;
    height: 28px;
  }
  nav .navbar-brand img {
    width: 100%;
  }
  nav .btn-login {
    width: 64px;
    height: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .wrapper {
    padding-top: 128px !important;
    padding-top: 75px !important;
  }
  .wrapper .question-btn .icon {
    width: 20px;
    height: 20px;
  }
  .wrapper .pt-32px {
    padding-top: 8.8px;
  }
  .wrapper .pt-32px .game-imgbox {
    height: 120px;
    max-height: 120 !important;
    border-radius: 0px;
    width: calc(100% + 16px);
    margin-left: -8px;
  }
  .wrapper .pt-32px .game-imgbox img {
    border-radius: 0px;
  }
  .wrapper .pt-32px .game-content {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .wrapper .pt-32px .game-content .game-smimgbox {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    overflow: hidden;
  }
  .wrapper .pt-32px .game-content .game-smimgbox img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-fit: fill;
    object-fit: fill;
    border-radius: 6px;
  }
  .wrapper .pt-32px .game-content .game-heading {
    font-size: 14px;
    padding-bottom: 4px;
  }
  .wrapper .pt-32px .game-content .icon {
    width: 16px;
    height: 16px;
  }
  .wrapper .pt-32px .game-content span {
    font-size: 12px;
    font-weight: 400;
  }
  .wrapper .details-heading {
    font-size: 16px;
  }
  .wrapper .input-label {
    font-size: 14px;
  }
  .wrapper .input-box {
    font-size: 14px;
    height: 44px;
  }
  .wrapper .check-name {
    margin-top: 10px;
    color: #39cd81;
  }
  .wrapper .check-name .icon {
    width: 16px !important;
    height: 16px !important;
  }
  .wrapper .check-name span {
    font-size: 14px;
  }
  .wrapper .min-h132px {
    min-height: 110px;
  }
  .wrapper .payment-card {
    min-height: 90px !important;
  }
  .wrapper .payment-card .left-coin .img-box {
    width: 40px;
    height: 40px;
  }
  .wrapper .payment-card .left-coin .coin-name {
    font-size: 12px !important;
    margin-left: 5px;
  }
  .wrapper .payment-card .right-coin {
    padding-right: 5px;
  }
  .wrapper .payment-card .right-coin .img-box {
    width: 16px;
    height: 16px;
    overflow: hidden;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .wrapper .payment-card .right-coin .img-box img {
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .wrapper .payment-card .right-coin .coin-value {
    font-size: 14px;
  }
  .wrapper .payment-card .right-coin .rupee {
    font-size: 8px;
  }
  .wrapper .payment-card .right-coin .old-price {
    font-size: 8px;
  }
  .wrapper .payment-card .right-coin .img-upibox {
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 5px;
  }
  .wrapper .payment-card .right-coin .img-upibox img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .wrapper .payment-card .right-coin .img-upimore {
    width: 8px;
    height: 8px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 25px;
  }
  .wrapper .payment-card .right-coin .img-upimore img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .wrapper .payment-card .left-upi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .wrapper .payment-card .left-upi .img-box {
    width: 64px;
    height: 30px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .wrapper .payment-card .left-upi .img-box img {
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .wrapper .payment-card .left-upi .coin-name {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
  }
  .cl-content {
    padding: 20px 12px !important;
    border: 1px solid #eaecf0;
  }
  .cr-badge {
    font-size: 8px !important;
  }
  .cl-badge {
    font-size: 8px !important;
  }
  .cl-title h4 {
    font-size: 12px !important;
  }
  .cl-title span {
    font-size: 8px !important;
  }
  .cl-icon {
    width: 40px !important;
    min-width: 40px !important;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .cl-icon img {
    width: 100%;
    display: block;
  }
  .cl-price {
    font-size: 14px !important;
    bottom: 15px !important;
    left: 13px;
  }
  .footer-socialicon h5 {
    font-size: 16px;
  }
  .footer-socialicon a {
    width: 20px;
    height: 20px;
  }
  .footer-socialicon .youtube {
    color: #ff0000;
    width: 24px;
    height: 24px;
  }
  .footer-socialicon .youtube img {
    width: 24px;
    height: 24px;
  }
  .copyright {
    font-size: 10px !important;
  }
  .detail-modal p {
    font-family: 'Roboto', sans-serif;
    font-size: 8px;
    font-weight: 500;
    color: #000;
  }
  .detail-modal .img-details {
    width: 100%;
    height: 114px;
    border-radius: 0px;
    overflow: hidden;
  }
  .detail-modal .img-details img {
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .pay-btnbox {
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    padding: 8px;
    -webkit-box-shadow: 0px 0px 4px 0px #00000040;
    box-shadow: 0px 0px 4px 0px #00000040;
  }
  .btn-pay {
    height: 48px;
    font-size: 14px;
  }
  body:has(.mobile-footer) footer {
    margin-bottom: 54px;
  }
  .dropdownList {
    position: fixed !important;
    margin-top: 0;
    left: auto;
    right: -280px;
    width: 260px;
    height: 100vh !important;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .dropdownList.show {
    right: 0;
  }
  .dropdownList .offcanvas-header {
    display: block !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: end !important;
  }
  .dropdownList .offcanvas-right {
    overflow-y: auto;
    height: calc(100vh - 100px);
    padding-bottom: 40px !important;
  }
  .dropdownList h5 {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  #columnslist {
    -webkit-column-count: 1;
    column-count: 1;
  }
  body:has(.language-dropdown.show) .lanlist-overlay {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .center-slider .slick-slide {
    height: 122px;
    border-radius: 8px !important;
  }
  .slick-dots li button:before {
    font-size: 10px !important;
    content: '•';
  }
  .populargame-heading {
    font-size: 16px;
  }
  .PopularGames .card {
    border-radius: 12px;
    padding: 8px;
    -webkit-box-shadow: 1px 1px 4px 0px #00000026;
    box-shadow: 1px 1px 4px 0px #00000026;
  }
  .PopularGames .card .img-box {
    border-radius: 12px;
  }
  .PopularGames .card .card-body {
    padding: 0;
  }
  .PopularGames .card .card-body .card-title {
    font-size: 8px;
    padding-top: 8px;
    margin: 0;
  }
  .OfficialStore-heading {
    font-size: 14px;
    padding-bottom: 8px;
  }
  .official-paragraph {
    font-size: 12px;
    padding-bottom: 24px;
  }
  .content-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .content-icon .icon-box {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 8px;
    margin-right: 8px;
  }
  .content-icon .paragraph h6 {
    font-size: 14px;
    padding-bottom: 8px;
  }
  .content-icon .paragraph p {
    font-size: 12px;
  }
  .ContactUs-Heading {
    font-size: 16px;
    text-align: center;
  }
  .social-card {
    padding-bottom: 24px;
  }
  .social-card .card {
    width: 104px;
    height: 94px;
    min-width: 104px;
    min-height: 94px;
    border-radius: 8px;
  }
  .social-card .card .card-body .whatsapp {
    width: 32px;
    height: 32px;
  }
  .social-card .card .card-body .email {
    width: 44px;
    height: 32px;
  }
  .social-card .card .card-body .card-title {
    font-size: 14px;
    padding-top: 6px;
  }
  .mobile-footer {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-shadow: 0px 0px 4px 0px #00000040;
    box-shadow: 0px 0px 4px 0px #00000040;
    height: 56px;
  }
  .mobile-footer .ft-btn {
    text-align: center;
    color: #00000075;
    -webkit-transition: 500ms;
    transition: 500ms;
  }
  .mobile-footer .ft-btn .icon {
    font-size: 18px;
  }
  .mobile-footer .ft-btn p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .mobile-footer .ft-btn:hover {
    color: #1a87d9;
  }
  .mobile-footer .ft-btn.active {
    color: #1a87d9;
  }
  .mobile-footer .ft-btn.active .icon-bg {
    border-radius: 25px;
    padding: 4px;
    width: 42px;
    max-width: 42px;
    background: #1a87d91a;
    -webkit-transition: 500ms;
    transition: 500ms;
  }
  .btn-coin {
    min-width: 100px;
    height: 30px;
    border: 1px solid #eaecf0;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 5px;
  }
  .btn-coin:hover,
  .btn-coin :active {
    opacity: 0.7;
  }
  .btn-coin img {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  .btn-coin span {
    font-size: 14px;
  }
  .use-btn {
    min-width: 68px;
    max-width: 94px;
    height: 30px;
  }
  .use-btn span {
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .addcoin-modal .modal-header {
    padding-bottom: 0px !important;
  }
  .addcoin-modal .modal-header .btn-close {
    padding-bottom: 0px;
  }
  .addcoin-modal h5 {
    font-size: 24px;
    padding-bottom: 24px;
    margin-top: -12px;
  }
  .addcoin-modal p {
    font-size: 14px;
  }
  .addcoin-modal .input-label,
  .addcoin-modal .input-box {
    font-size: 14px;
  }
  .addcoin-modal .input-box {
    height: 44px;
  }
  .addcoin-modal .input-box:focus {
    -webkit-box-shadow: 0px 0px 0px 3px #1a87d940;
    box-shadow: 0px 0px 0px 3px #1a87d940;
  }
  .addcoin-modal button {
    font-size: 16px;
    height: 44px !important;
    margin-top: 24px;
  }
  .login-form {
    border-radius: 8px;
    padding: 14px;
    border: 1px solid #eaecf0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .login-form h2 {
    font-size: 24px;
    padding-bottom: 14px;
  }
  .login-form h6 {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .login-form .input-box {
    height: 44px;
  }
  .login-form button {
    height: 44px;
    margin-top: 116px;
    font-size: 16px;
  }
  .login-form h5 {
    overflow: hidden;
    color: #00000080;
    margin-top: 62px;
  }
  .login-form p {
    font-size: 14px;
    padding-top: 10px;
  }
  .login-form .center-text {
    text-align: center;
  }
  .login-form .right-line:after {
    content: '';
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 1px solid #00000026;
  }
  .login-form .left-line:before {
    content: '';
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-left: -100%;
    margin-right: 10px;
    border-top: 1px solid #00000026;
  }
  .login-form .PhoneInput {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .login-form .PhoneInput .PhoneInputInput {
    height: 44px;
    padding-left: 10px;
    font-size: 14px;
  }
  .login-form .PhoneInput .PhoneInputCountry {
    width: 84px;
    margin-right: 10px !important;
  }
  .login-form .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
    width: 20px !important;
    height: 20px !important;
  }
  .login-form .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon .PhoneInputCountryIconImg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .login-form .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    margin-left: 12px;
  }
  .login-social {
    margin-top: 22px;
  }
  .login-social .btn-social {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
  }
  .login-social .btn-social img {
    width: 40px;
    height: 40px;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .login-social .fb-btn {
    margin: 0 26px;
  }
  .otp-input-fields {
    gap: 10px;
  }
  .otp-input-fields input {
    width: 42px !important;
    height: 48px !important;
    font-size: 16px;
    /* Firefox */
  }
  .otp-input-fields input::-webkit-outer-spin-button,
  .otp-input-fields input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .otp-input-fields input[type='number'] {
    -moz-appearance: textfield;
  }
  .mainheaderFooter {
    display: none;
  }
  .nav-mobile {
    display: block;
    background-color: #f9fafb;
  }
  .footer-mobile {
    display: block;
    background-color: #f9fafb;
  }
  .country-btn span {
    font-size: 14px !important;
    font-weight: 400;
  }
  .country-btn img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
  .slick-dots li.slick-active button:before {
    font-size: 16px !important;
  }
  .slick-dots li button:before {
    font-size: 12px !important;
  }
  .register .input-label,
  .register .input-box {
    font-size: 14px;
  }
  .register .input-box {
    height: 44px;
  }
  .register .condition {
    font-size: 14px;
    padding-top: 124px;
  }
  .register .condition a {
    text-decoration: none;
    color: #1a87d9;
  }
  .register button {
    margin-top: 15px;
    height: 44px;
  }
  .terms-content .TermsConditions-Heading {
    font-size: 24px;
  }
  .terms-content .terms-paragraph {
    font-size: 12px;
  }
  .terms-content .Chapter-Heading {
    font-size: 16px;
  }
  .terms-content .sub-heading {
    font-size: 14px;
  }
  .profile-wrapper {
    font-family: 'Roboto', sans-serif;
  }
  .profile-wrapper .title {
    font-size: 24px;
  }
  .profile-wrapper form {
    width: 100% !important;
    min-width: 100%;
  }
  .profile-wrapper form .input-label {
    font-size: 14px;
  }
  .profile-wrapper form .input-box {
    height: 44px;
    font-size: 14px;
  }
  .profile-wrapper form button {
    width: 100%;
    height: 44px;
    font-size: 16px;
  }
  .order-tabs .nav-link {
    font-size: 14px;
  }
  .order-tabs .card {
    border: 1px solid #eaecf0;
    height: 82px !important;
    border-radius: 8px;
  }
  .order-tabs .card .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px;
  }
  .order-tabs .card .card-body .game-img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 6px;
  }
  .order-tabs .card .card-body .game-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .order-tabs .card .card-body .order-detail .game-name {
    font-size: 14px;
  }
  .order-tabs .card .card-body .order-detail .order-complete {
    font-size: 14px;
  }
  .order-tabs .card .card-body .order-detail .order-processing {
    font-size: 14px;
  }
  .order-tabs .card .card-body .order-detail .order-failed,
  .order-tabs .card .card-body .order-detail .order-refunded {
    font-size: 14px;
  }
  .order-tabs .card .card-body .card-subtitle {
    font-size: 12px;
    padding-top: 0px;
  }
  .order-tabs .card .card-body .card-ammount {
    font-size: 16px;
  }
  .search-userid {
    width: 220px;
    height: 32px;
    position: relative;
  }
  .search-userid input {
    padding-left: 8px;
    font-size: 14px;
  }
  .search-userid button {
    top: 5px;
    right: 5px;
    width: 22px;
    height: 22px;
  }
  .order-completed {
    width: 100%;
    max-width: 576px;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 16px;
    padding: 16px;
  }
  .order-completed .icon {
    font-size: 48px;
    color: #1a87d9;
  }
  .order-completed .order-title {
    font-size: 16px;
  }
  .order-completed .puchase-title {
    font-size: 14px;
  }
  .order-completed .left-title {
    font-size: 12px;
  }
  .order-completed .right-title {
    font-size: 12px;
  }
  .order-completed button {
    height: 44px;
  }
  .order-detailcard {
    width: 100%;
    max-width: 544px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0px;
    border-radius: 8px;
  }
  .order-detailcard .gameImg {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 6px;
  }
  .order-detailcard .gameName {
    font-size: 14px;
    line-height: 16.41px;
  }
  .order-detailcard .left-order {
    font-size: 12px;
  }
  .order-detailcard .right-order {
    font-size: 12px;
  }
  .order-detailcard .start-br {
    border-bottom: 1px solid #00000026;
  }
  .order-detailcard .mid-br {
    border-bottom: 1px solid #00000026;
  }
  .order-detailcard .last-br {
    border-bottom: 1px solid #00000026;
  }
  .order-detailbtn button {
    width: 100%;
    height: 44px;
  }
  .wallet .title {
    font-size: 24px;
  }
  .wallet .card {
    height: 80px;
    border-radius: 12px;
    padding: 8px;
    background: linear-gradient(89.51deg, #1a87d9 0.42%, #235cc8 84.9%);
    border: none;
    color: #ffffff;
  }
  .wallet .card .card-body .left-coindetails {
    height: 100% !important;
    height: 46px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .wallet .card .card-body .left-coindetails .coin-title {
    font-size: 12px;
  }
  .wallet .card .card-body .left-coindetails .coin-value {
    font-size: 24px;
  }
  .wallet .card .card-body .left-coindetails .coin-img {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .wallet .card .card-body .left-coindetails .coin-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .wallet .card .card-body .wallet-btn button {
    width: 105px;
    height: 36px;
    font-size: 14px;
    padding: 0 8px;
  }
  .wallet .card .card-body .wallet-btn button .icon {
    font-size: 24px;
  }
  .wallet .transaction-title {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #00000080;
  }
  footer h6 {
    font-size: 20px;
    pointer-events: all;
    margin-bottom: 0px;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  footer h6.collapse-mobile[aria-expanded='true'] {
    border-bottom: 1px solid transparent;
    border: none;
  }
  footer h6.collapse-mobile[aria-expanded='true']::after {
    margin-top: 7px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  footer h6.collapse-mobile::after {
    display: block;
  }
  footer .collapse:not(.show) {
    display: none;
  }
  footer .navbar-brand {
    height: 48pxn !important;
  }
  footer .navbar-brand img {
    height: 100%;
  }
  .desktop-banner {
    display: none;
  }
  .mobile-banner {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .col-lg-340 {
    max-width: 340px;
  }
}

@media screen and (min-width: 767px) {
  .col-md-340 {
    max-width: 340px;
  }
}

@media screen and (min-width: 575px) {
  .col-sm-340 {
    max-width: 340px;
  }
}

.input-box:focus {
  border: 1px solid #1a87d9 !important;
  -webkit-box-shadow: 0px 0px 0px 4px #1a87d940 !important;
  box-shadow: 0px 0px 0px 4px #1a87d940 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
  padding-right: 0;
  padding-left: 0;
  background-position: no;
}
